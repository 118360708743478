import {
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Yakureki } from '../../types/yakureki'
import MuiAlert from '@mui/material/Alert'

type Props = {
  yakureki: Yakureki
  index: number
  isSending: boolean
}

export const SoapLoadingToast: FC<Props> = ({ yakureki, index, isSending }) => {
  const prevIsProgress = useRef(yakureki.is_progress)
  const prevIsFail = useRef(yakureki.is_fail)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [isFailed, setIsFailed] = useState<boolean>(false)

  useEffect(() => {
    if (prevIsProgress.current && !yakureki.is_progress) {
      if (!prevIsFail.current && yakureki.is_fail) {
        setIsFailed(true)
      } else {
        setIsCompleted(true)
      }
    }
    prevIsProgress.current = yakureki.is_progress
  }, [yakureki.is_progress, yakureki.is_fail])

  const SnackbarStyle = {
    transform: `translateY(-${50 * index}px) translateX(-50%)`,
  }

  const SendingSuccessToast = (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isCompleted}
      style={SnackbarStyle}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="success"
        onClose={() => {
          setIsCompleted(false)
        }}
      >
        <Typography>{yakureki.label}さんのSOAPを作成しました</Typography>
      </MuiAlert>
    </Snackbar>
  )

  const SendingFailToast = (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isFailed}
      style={SnackbarStyle}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="error"
        onClose={() => {
          setIsFailed(false)
        }}
      >
        <Typography>{yakureki.label}さんのSOAP作成に失敗しました</Typography>
      </MuiAlert>
    </Snackbar>
  )

  const LoadingOverlay = (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isSending || yakureki.is_progress}
    >
      <Stack
        direction="row"
        sx={{
          bgcolor: 'rgba(0, 0, 0, 0.5)', // 透明度を追加（例: 黒の半透明）
          width: 200,
          height: 20,
          borderRadius: 1,
          p: 2,
          alignItems: 'center', // 高さを統一するための中央揃え
        }}
      >
        <CircularProgress size={24} /> {/* 高さを指定して統一 */}
        <Typography
          textAlign="center"
          color={'#fff'}
          sx={{ flex: 1, lineHeight: '48px' }}
          fontSize={24}
        >
          SOAP作成中
        </Typography>
      </Stack>
    </Snackbar>
  )
  return (
    <>
      {SendingSuccessToast}
      {LoadingOverlay}
      {SendingFailToast}
    </>
  )
}
