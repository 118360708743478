import { Box, Button, Stack, TextField } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { Yakureki } from '../../types/yakureki'

type Props = {
  name: string
  setName: (name: string) => void
  isRecording: boolean
  audioURL?: string | null
  recordButton: JSX.Element
  yakurekiList: Yakureki[]
}

export const RecordModalBody: FC<Props> = ({
  name,
  setName,
  isRecording,
  audioURL,
  recordButton,
  yakurekiList,
}) => {
  useEffect(() => {
    setName(createLabel())
  }, [yakurekiList.length])

  const createLabel = () => {
    const today = new Date()
    const yakurekiListToday = yakurekiList.filter((yakureki) => {
      // 日付部分のみを取り出して比較
      const yakurekiDate = new Date(yakureki.created_at)
      return (
        yakurekiDate.getFullYear() === today.getFullYear() &&
        yakurekiDate.getMonth() === today.getMonth() &&
        yakurekiDate.getDate() === today.getDate()
      )
    })
    const yakurekiLength = yakurekiListToday.length
    const label = '患者-' + String(yakurekiLength + 1)
    return label
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // 縦中央揃え
        height: '80%',
        padding: '8px',
      }}
    >
      <Stack
        sx={{
          width: '60%',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
        direction="column"
        spacing={2}
      >
        <TextField
          label={'患者氏名'}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {recordButton}
      </Stack>
    </Box>
  )
}
