import React, { FC, memo, NamedExoticComponent } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  Stack,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material'
import { theme } from '../../styles/theme'
import { CloseIcon } from '../../icons/CloseIcon'
import { formatTimeInJPFull } from '../../helpers'
import { Yakureki } from '../../types/yakureki'
import { serviceName } from '../../constants/const'

type Props = {
  isOpen: boolean
  onClose: () => void
  yakurekiList: Yakureki[]
  selectedYakureki?: Yakureki
  selectYakureki: (yakurakiId: string) => void
  BottomButtons: React.ReactNode[]
}

export const DrawerPrescList: FC<Props> = ({
  isOpen,
  onClose,
  yakurekiList,
  selectedYakureki,
  selectYakureki,
  BottomButtons,
}) => {
  return (
    <Drawer
      anchor={'left'}
      open={isOpen}
      onClose={onClose}
      sx={{
        position: 'relative',
        '& .MuiDrawer-paper': {
          backgroundColor: theme.palette.primary.dark,
        },
      }}
      style={{
        opacity: 0.9,
        zIndex: 1301,
      }}
    >
      <Stack direction="column">
        <Box
          sx={{ height: '5vh' }}
          left={0}
          top={0}
          bgcolor={'primary.dark'}
          color="#fff"
          p={1}
        >
          <Button
            onClick={onClose}
            startIcon={<CloseIcon />}
            style={{ color: '#fff' }}
          >
            {selectedYakureki ? selectedYakureki.label : serviceName}
          </Button>
        </Box>
        <Box
          overflow="scroll"
          bgcolor={'primary.dark'}
          color="#fff"
          sx={{ width: 300, height: '100vh' }}
          role="presentation"
        >
          <List>
            {selectedYakureki &&
              yakurekiList.map((yakureki) => (
                <DrawerItem
                  key={yakureki.id}
                  yakureki={yakureki}
                  selectYakureki={selectYakureki}
                  isSelected={yakureki.id === selectedYakureki.id}
                  isProgress={yakureki.is_progress}
                  isFail={yakureki.is_fail}
                />
              ))}
          </List>
        </Box>
        {BottomButtons.map((button, index) => (
          <Stack
            key={index}
            p={1}
            sx={{
              position: 'fixed',
              bottom: `${(BottomButtons.length - 1 - index) * 7}vh`,
              width: 270,
              height: '5vh',
              borderTop: '1px solid #fff',
              justifyContent: 'center',
            }}
            bgcolor={theme.palette.primary.dark}
          >
            {button}
          </Stack>
        ))}
      </Stack>
    </Drawer>
  )
}

type DrawerProps = {
  yakureki: Yakureki
  selectYakureki: (yakurekiId: string) => void
  isSelected: boolean
  isProgress: boolean
  isFail: boolean
}

const DrawerItem: NamedExoticComponent<DrawerProps> = memo(
  ({ yakureki, selectYakureki, isSelected, isProgress, isFail }) => {
    console.log('yakureki', yakureki)
    return isProgress ? (
      <ListItem
        key={yakureki.id}
        style={{
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.secondary.main,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="space-between"
          width="100%"
        >
          <CircularProgress
            style={{
              width: '24px',
              height: '24px',
              color: '#fff',
            }}
          />
          <Box>
            <ListItemText primary={yakureki.label} />
          </Box>
          <Box>
            <ListItemText primary={'作成中...'} />
          </Box>
        </Stack>
      </ListItem>
    ) : (
      <ListItem
        button
        onClick={() => selectYakureki(yakureki.id)}
        key={yakureki.id}
        style={
          isSelected
            ? {
                backgroundColor: '#fff',
                color: theme.palette.primary.dark,
              }
            : {
                borderTop: '0.5px solid #fff',
                backgroundColor: yakureki.copy_done
                  ? theme.palette.secondary.dark
                  : theme.palette.primary.dark,
              }
        }
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <ListItemText
              primary={
                yakureki.copy_done ? '(済)' + yakureki.label : yakureki.label
              }
            />
          </Box>
          <Box>
            <ListItemText primary={formatTimeInJPFull(yakureki.created_at)} />
          </Box>
        </Stack>
      </ListItem>
    )
  },
  (prev, next) => {
    return (
      prev.yakureki.label === next.yakureki.label &&
      prev.yakureki.created_at === next.yakureki.created_at &&
      prev.isSelected === next.isSelected
    )
  }
)

DrawerItem.displayName = 'DrawerItem'
