type KeyValueType = {
  userId: string
  IsAudioUpload: boolean
}

type KeyType = keyof KeyValueType

export const localStorageWrapper = {
  setItem: <T extends KeyType>(key: T, item: KeyValueType[T]): void => {
    localStorage.setItem(key, JSON.stringify(item))
  },
  getItem: <T extends KeyType>(key: T): KeyValueType[T] => {
    const itemStr = localStorage.getItem(key)
    return itemStr === null ? null : JSON.parse(itemStr)
  },
}
