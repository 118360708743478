import React, { useRef } from 'react'
import { Button } from '@mui/material'
import { postAudio } from '../../apis/yakureki'
import { CustomFab } from '../molecules/CustomFab'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { theme } from '../../styles/theme'

export const AudioUploadButton: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => {
    // input のクリックイベントを発火
    fileInputRef.current?.click()
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (file) {
      await postAudio(file) // ファイルが選択されたら postAudio 関数を呼び出す
    }
  }

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }} // input を非表示にする
        accept="audio/*" // 音声ファイルのみを受け入れる
      />
      <CustomFab
        sx={{
          '&:hover': {
            backgroundColor: theme.palette.primary.translucent,
            color: '#fff',
          },
          bottom: 160,
        }}
        onClick={handleButtonClick}
      >
        <FileUploadIcon style={{ fontSize: 35 }} />
      </CustomFab>
    </>
  )
}
