import { Yakureki, YakurekiDetail } from '../types/yakureki'
import client from './client'
import { AxiosPromise } from 'axios'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'

const ffmpeg = new FFmpeg()

export const getYakurekiDetail = (
  yakurekiId: string
): AxiosPromise<YakurekiDetail> => client.get(`/yakureki/${yakurekiId}`)

export const getYakurekiLatest = (): AxiosPromise<YakurekiDetail> =>
  client.get(`/yakureki/latest`)

export const getYakurekiList = (): AxiosPromise<Yakureki[]> =>
  client.get(`/yakureki/`)

export const patchCopyDone = (yakurekiId: string): AxiosPromise<Yakureki[]> =>
  client.patch(`/yakureki/${yakurekiId}/copy_done/`)

export const postYakurekiAudio = async (
  audio: string,
  label: string
): Promise<AxiosPromise<string>> => {
  console.log('start------')
  const response = await fetch(audio)
  console.log('fetch------')
  const blob = await response.blob()
  console.log('response------')
  const mp3Blob = await convertToMp3(blob)
  console.log('mp3Blob', mp3Blob)
  const formData = new FormData()
  formData.append('audio', mp3Blob, 'audio.mp3')
  formData.append('label', label)
  return client.post('/yakureki/', formData)
}

export const postAudio = async (file: File): Promise<void> => {
  const now = new Date()
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const seconds = now.getSeconds().toString().padStart(2, '0')
  const label = `${hours}${minutes}${seconds}`
  const response = await fetch(URL.createObjectURL(file))
  const blob = await response.blob()
  const mp3Blob = await convertToMp3(blob)
  const formData = new FormData()
  formData.append('audio', mp3Blob, 'audio.mp3')
  formData.append('label', label)
  return await client.post('/yakureki/', formData)
}

export const convertToMp3 = async (audioBlob: Blob): Promise<Blob> => {
  if (!ffmpeg.loaded) {
    try {
      console.log('ffmpeg_loading')
      await ffmpeg.load()
      console.log('ffmpeg_loaded')
    } catch (error) {
      console.error('FFmpeg load failed:', error)
    }
  }

  const inputFileName = 'input.webm'
  const outputFileName = 'output.mp3'

  // FFmpeg 仮想ファイルシステムにファイルを書き込む
  await ffmpeg.writeFile(inputFileName, await fetchFile(audioBlob))
  console.log('writeFile')

  // MP3 への変換を実行
  await ffmpeg.exec(['-i', inputFileName, '-q:a', '2', outputFileName])

  console.log('exec')

  // 変換されたファイルを仮想ファイルシステムから読み込む
  const data = await ffmpeg.readFile(outputFileName)

  console.log('readFile')

  // Blob に変換
  return new Blob([data], { type: 'audio/mp3' })
}
