import React, { useState, useRef, useCallback, useEffect } from 'react'
import {
  Box,
  Button,
  IconButton,
  Typography,
  Stack,
  CircularProgress,
  Snackbar,
} from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import MicIcon from '@mui/icons-material/Mic'
import useSound from 'use-sound'
import { theme } from '../styles/theme'
import { postYakurekiAudio } from '../apis/yakureki'

export const useRecordAudio = (
  name: string,
  setName: (name: string) => void,
  closeModal: () => void,
  fetch: () => void
) => {
  const [audioURL, setAudioURL] = useState<string | null>(null)
  const [isRecording, setIsRecording] = useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isSendingComplete, setIsSendingComplete] = useState<boolean>(false)
  const [SoapOwnerName, setSoapOwnerName] = useState<string>('')
  const [isAudioStarted, setIsAudioStarted] = useState<boolean>(false)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const audioRef = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    // ユーザーからのマイクアクセス許可をリクエスト
    const getMicrophoneAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/mp4', // Safari でサポートされる形式
        })
        console.log('mediaRecorder', mediaRecorder.mimeType)
        setMediaRecorder(mediaRecorder)
      } catch (error) {
        console.error('Error accessing microphone:', error)
      }
    }
    getMicrophoneAccess()
  }, [])

  const handleKeyDownForRecord = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && event.shiftKey) {
      console.log('Enter key was pressed')
      console.log('audioURL', audioURL)
      if (isRecording) {
        stopRecording()
      } else if (audioURL) {
        sendAudio()
      } else {
        startRecording()
      }
    } else if (event.key === 'Backspace' && event.shiftKey) {
      console.log('Shift + Backspace was pressed')
      if (audioURL) {
        setAudioURL(null)
      }
    }
  }

  const playSound = async () => {
    if (audioRef.current) {
      if (isAudioStarted) {
        audioRef.current.pause()
        setIsAudioStarted(false)
      } else {
        await audioRef.current.play()
        setIsAudioStarted(true)
      }
    }
  }

  const startRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'inactive') {
      mediaRecorder.start()
      setIsRecording(true)

      const audioChunks: BlobPart[] = []
      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        audioChunks.push(event.data)
      }

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' })
        const audioUrl = URL.createObjectURL(audioBlob)
        setAudioURL(audioUrl)
        console.log('audioUrl', audioUrl)
        setIsRecording(false)
      }
    }
  }

  const sendAudio = async () => {
    // TODO:実際に非同期処理を行えるようになったらpost後にモーダルを閉じるようにする
    // TODO:setTmpNameもデモ用の負債なので非同期処理を行えるようになったらpost後にモーダルを閉じるようにする
    setIsSending(true)
    setAudioURL(null)
    setSoapOwnerName(name)
    setName('')
    closeModal()
    audioURL && (await postYakurekiAudio(audioURL, name))
    setIsSending(false)
    setIsSendingComplete(true)
    fetch()
  }

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop()
    }
  }

  const recordButton = (
    <>
      {audioURL ? (
        <Stack>
          <audio ref={audioRef} src={audioURL} />
          <IconButton size="small" onClick={() => playSound()}>
            {isAudioStarted ? (
              <PauseCircleIcon style={{ fontSize: 300 }} />
            ) : (
              <PlayCircleFilledWhiteIcon style={{ fontSize: 300 }} />
            )}
          </IconButton>
          <Stack direction="row" justifyContent={'space-between'} spacing={2}>
            <Box p={1}>
              <Button
                sx={{
                  color: '#fff',
                  bgcolor: theme.palette.secondary.dark,
                  '&:hover': {
                    bgcolor: theme.palette.primary.main, // ホバー時の背景色
                    color: '#000', // ホバー時の文字色
                  },
                  width: '100%',
                }}
                onClick={() => setAudioURL(null)}
              >
                録り直し(Shift+Del)
              </Button>
            </Box>
            <Box p={1}>
              <Button
                sx={{
                  color: '#fff',
                  bgcolor: theme.palette.success.main,
                  '&:hover': {
                    bgcolor: theme.palette.success.main, // ホバー時の背景色
                    color: '#000', // ホバー時の文字色
                  },
                  width: '100%',
                }}
                onClick={sendAudio}
              >
                SOAP作成(Shift+⏎)
              </Button>
            </Box>
          </Stack>
        </Stack>
      ) : (
        <Stack p={1}>
          {isRecording ? (
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
              <Typography sx={{ color: '#659AD2', fontWeight: 'bold' }}>
                録音中...
              </Typography>
              <MicIcon style={{ fontSize: 300, color: '#659AD2' }} />
              <Button
                sx={{
                  color: '#fff',
                  width: '100%',
                  bgcolor: theme.palette.primary.dark,
                  '&:hover': {
                    bgcolor: theme.palette.primary.main, // ホバー時の背景色
                    color: '#000', // ホバー時の文字色
                  },
                }}
                onClick={stopRecording}
              >
                録音停止(Shift+⏎)
              </Button>
            </Stack>
          ) : (
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
              <MicIcon style={{ fontSize: 300 }} />
              <Button
                sx={{
                  color: '#fff',
                  width: '100%',
                  bgcolor: theme.palette.primary.dark,
                  '&:hover': {
                    bgcolor: theme.palette.primary.main, // ホバー時の背景色
                    color: '#000', // ホバー時の文字色
                  },
                }}
                variant="outlined"
                onClick={startRecording}
              >
                録音開始(Shift+⏎)
              </Button>
            </Stack>
          )}
        </Stack>
      )}
    </>
  )

  return {
    isRecording,
    audioURL,
    recordButton,
    isSending,
    handleKeyDownForRecord,
    mediaRecorder,
  }
}
